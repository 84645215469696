import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import Carousel from 'nuka-carousel';
import { ourValues } from '../../data/carousel_data';
import useWindowSize from '../../hooks/use-window-size';
import ValueSlide from './ValueSlide';
import ArrowLeft from '../CarouselCustomControls/ArrowLeft';
import ArrowRight from '../CarouselCustomControls/ArrowRight';
import bg from '../../images/svg/long-semi-circle.svg';
import OurValuesStyles from './OurValuesStyles';

const { Title } = Typography;

const OurValues = (props) => {
  const { values } = props;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;
    const isTablet = width > 576 && width < 600;
    const isSreenMD = width >= 600 && width < 780;
    const isSreenLG = width >= 780 && width < 1200;
    const screenXL = width >= 1200;

    const slides = () => {
      if (isMobileOnly || isTablet) return 1.5;
      if (isSreenMD) return 2.2;
      if (isSreenLG) return 3.2;
      if (screenXL) return 4.5;
      return 4.5;
    };
    const spacing = () => {
      if (isMobileOnly) return 10;
      if (isTablet) return 32;
      return 32;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);
  return (
    <section className="our-values" style={{ backgroundImage: `url(${bg})` }}>
      <Title level={2}>
        Our values
        <span className="blue_dot">.</span>{' '}
      </Title>
      <div className="lateral-shadow-right">
        <Carousel
          slidesToShow={numberOfSlides}
          cellSpacing={cellSpacing}
          enableKeyboardControls
          dragging
          swiping
          wrapAround
          className="our-values__car"
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          renderBottomCenterControls={() => null}
        >
          {(values || ourValues).map((values, idx) => (
            <ValueSlide
              key={`values__${values.title}`}
              position={idx}
              title={values.title}
              subtext={values.subtext}
              iconImg={values.iconImg}
            />
          ))}
        </Carousel>
      </div>

      <OurValuesStyles />
    </section>
  );
};

export default OurValues;
