import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import Image from "../ImageQuerys/UsCarousel";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const ValueSlide = (props) => {
    const { title, subtext, iconImg, position } = props;

    return (
        <div className="our-values__slide">
            <span>0{(position + 1).toString()}</span>
            <div className="our-values__slide-icon">
                <Image imageName={iconImg} />
            </div>
            <Heading level={3} injectionType={4}>
                {title}
            </Heading>
            <p>{subtext}</p>

            <style jsx>{`
        @media screen and (min-width: 350px) {
        .our-values__slide {
            min-height: 530px;
            }
        }
        @media screen and (min-width: 400px) {
            .our-values__slide {
                min-height: 462px;
            }
        }
        @media screen and (min-width: 450px) {
            .our-values__slide {
                    min-height: 415px;
            }
        }
        @media screen and (min-width: 520px) {
            .our-values__slide {
                    min-height: 395px;
            }
        }
        @media screen and (min-width: 600px) {
            .our-values__slide {
                    min-height: 462px;
            }
        }
        @media screen and (min-width: 650px) {
            .our-values__slide {
                min-height: 415px;
            }
        }
        @media screen and (min-width: 780px) {
            .our-values__slide {
                  min-height: 528px;
            }
        }
        @media screen and (min-width: 900px) {
            .our-values__slide {
              min-height: 440px;
            }
        }
        @media screen and (min-width: 1200px) {
            .our-values__slide {
              min-height: 505px;
            }
        }
        @media screen and (min-width: 1300px) {
            .our-values__slide {
                min-height: 462px;
            }
        }  
        @media screen and (min-width: 1400px) {
            .our-values__slide {
                min-height: 415px;
            }
        }
        `}
            </style>
        </div>
    );
};

ValueSlide.propTypes = {
    title: PropTypes.string,
    subtext: PropTypes.string,
    iconImg: PropTypes.string,
    position: PropTypes.number,
};
ValueSlide.defaultProps = {
    title: "",
    subtext: "",
    iconImg: "",
    position: "",
};

export default ValueSlide;
