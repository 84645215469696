import React from "react";
import { viewports, colors } from "../../style-vars";

const { mdOrSmaller, mdOnly } = viewports;
const { white, black, primary } = colors;

const OurValuesStyles = () => (
  <style jsx global>{`
    .our-values {
      position: relative;
      min-height: 350px;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 40px 0 100px 100px;
    }
    @media ${mdOrSmaller} {
      .our-values {
        padding: 40px 0 100px 32px;
      }
    }
    .our-values h2 {
      font-weight: 800 !important;
      margin-bottom: 32px;
    }
    .our-values__car {
      overflow: visible !important;
    }
    .our-values__car .slider-frame {
      overflow: visible !important;
    }
    .our-values__slide {
      padding: 32px;
      border-radius: 8px;
      min-height: 400px;
      background-color: ${white};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07),
        20px 10px 40px rgba(47, 80, 167, 0.1);
    }
    @media ${mdOnly} {
      .our-values__slide {
        min-height: 430px;
      }
    }
    @media screen and (min-width: 576px) {
        .our-values__slide {
          min-height: 485px;
        }
     }
     @media screen and (min-width: 600px) {
        .our-values__slide {
          min-height: 462px;
        }
     }
     @media screen and (min-width: 780px) {
        .our-values__slide {
          min-height: 528px;
        }
     }
     @media screen and (min-width: 900px) {
        .our-values__slide {
          min-height: 440px;
        }
    }
    @media screen and (min-width: 1200px) {
        .our-values__slide {
          min-height: 505px;
        }
    }     
    .our-values__slide span {
      font-size: 16px;
      color: ${primary};
    }
    .our-values__slide h3 {
      color: ${black} !important;
      margin-top: 16px !important;
      margin-bottom: 12px;
      font-size: 20px;
    }
    .our-values__slide p {
      font-size: 14px;
      color: #6e7682;
    }
    .our-values__slide-icon {
      margin-top: 16px;
      width: 64px;
      height: 64px;
      border-radius: 8px;
      background-color: #e8f0ff;
    }
    .our-values__slide-icon img {
      position: absolute !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: 32px !important;
      object-fit: scale-down !important;
    }
  `}</style>
);

export default OurValuesStyles;
