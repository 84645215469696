export const solutionsSection = {
  title: "One partner. Everything you need",
  subText: `We’ll help you create a custom solution that combines the hardware \n and software that makes sense for your business.`,
  // SecondaryCta: {
  //   ctaTitle: 'View all',
  //   ctaTarget: '/page-2',
  // },
};
export const solutionsSlides = [
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Reporting",
    link: "/products/reporting",
    img: "Reporting.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website & E-commerce",
    link: "/products/e-commerce",
    img: "E-commerce & websites.png",
  },
  {
    title: "Retail",
    link: "/retail",
    img: "retail_asset.png",
  },
  {
    title: "Restaurant platform",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Labor Management",
    link: "/products/labor-management",
    img: "Labor-Management.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Mobile",
    link: "/products/mobile",
    img: "Mobile.png",
  },
  {
    title: "Capital",
    link: "/products/capital",
    img: "Capital.png",
  },
  // {
  //   title: 'Payroll',
  //   link: '/products/payroll',
  //   img: 'Payroll.png',
  // },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
];

export const ourValues = [
  {
    title: "Be the client.",
    subtext:
      "We’re here to help your business succeed by putting ourselves into your shoes and anticipating your needs.",
    iconImg: "card.png",
  },
  {
    title: "One for all. All for one.",
    subtext:
      "We encourage diversity—of culture, background, experience, and thought—and the dynamic teamwork it takes to bring new ideas to life.",
    iconImg: "team.png",
  },
  {
    title: "Learn, question, transform, repeat.",
    subtext:
      "We're always learning and growing to provide you with the most innovative and functional products.",
    iconImg: "repeat.png",
  },
  {
    title: "Make it personal.",
    subtext:
      "We always go the extra mile to tailor solutions that make the most sense for your business.",
    iconImg: "hands.png",
  },
  {
    title: "Be authentic.",
    subtext:
      "We do business the right way by being honest and transparent. We believe in earning our clients’ business every day.",
    iconImg: "magid.png",
  },
  {
    title: "Think big, act small.",
    subtext:
      "No matter our size or reach, we will always bring the same heart and hustle to the way we do business. We’ll remember where we came from, pay attention to every detail, and work to make every client experience exceptional.",
    iconImg: "magid.png",
  },
];
